import styled, { createGlobalStyle } from 'styled-components';
import { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';

import balajiImage from './images/balaji.png';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }
  
  body {
    background: #121212;
    font-family: 'Poppins', sans-serif;
  }
`;

const Wrapper = styled.div`
  min-height: calc(100vh - 40px);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

const Image = styled.img`
  width: 500px;
  max-width: 100%;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const Link = styled.a`
  display: block;
`;

const Heading1 = styled.h1`
  margin-top: 20px;
  font-size: 50px;
  line-height: 60px;
`;

const Heading2 = styled.h2`
  margin-top: 20px;
  font-size: 30px;
  line-height: 40px;
`;

const Paragraph = styled.p`
  margin-top: 10px;
`;

const App = () => {
  const [counter, setCounter] = useState(0);
  const [btcPriceUsd, setBtcPriceUsd] = useState(null);
  const [isLoadingBtcPrice, setIsLoadingBtcPrice] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((current) => current + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const updateBtcPriceUsd = async () => {
    if (isLoadingBtcPrice) return;
    setIsLoadingBtcPrice(true);
    try {
      const { data } = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd');
      setBtcPriceUsd(new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(data.bitcoin.usd));
    } catch (e) {
      setBtcPriceUsd((current) => current ?? null);
    }
    setIsLoadingBtcPrice(false);
  };

  useEffect(() => {
    if (counter === 0 || counter % 5 === 0) {
      updateBtcPriceUsd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  const eventDate = moment(+new Date('2023-03-18T09:38:21.000Z')).add('90', 'days');
  const durationInMilliseconds = eventDate.diff(moment());
  const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
  const minutes = Math.floor((durationInMilliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((durationInMilliseconds / (1000 * 60 * 60)) % 24);
  const days = Math.floor(durationInMilliseconds / (1000 * 60 * 60 * 24));

  return (
    <Wrapper>
      <GlobalStyle />
      <Link href="https://twitter.com/balajis/status/1637025637901938689">
        <Image src={balajiImage} alt="Balaji" />
      </Link>
      <Heading1>Ar we there yet?</Heading1>
      <Paragraph>
        {btcPriceUsd && `1 BTC = ${btcPriceUsd} USD = `}Not quite...
      </Paragraph>
      <Heading2>How much time we got left?</Heading2>
      <Paragraph>
        {days} days {hours} hours {minutes} minutes {seconds} ± 420 seconds
      </Paragraph>
    </Wrapper>
  );
}

export default App;
